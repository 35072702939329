/**
 * Gets a timestamp that's the same on both server & client side so that we can avoid hydration mismatch issues.
 * Yes, it looks rediculous, but it works.
 *
 * @param key - the key to store the timestamp in the cache (important when using multiple instances of this composable)
 * @returns the server synced timestamp
 */
export function useGetServerSyncedTimestamp(key = 'timestamp') {
  const { isHydrating } = useNuxtApp()
  const now = Date.now()
  const timestamp = useState<number>(key, () => now)

  if (!isHydrating && timestamp.value < now) timestamp.value = now

  return {
    timestamp,
  }
}
